<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Warehouses')}}</h2>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="warehouses"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Code')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </shipblu-td>

          <shipblu-td>
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
              @click="selected = []"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px" v-if="$store.state.AppActiveUser.userRole !== 'finance'">
                <!-- ACTIONS FOR ON CREATED -->
                <vs-dropdown-item
                  @click="warehouseShipments(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="PackageIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Orders')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="warehouseData(data[indextr], 'slots')"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="GridIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Slots')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="warehouseData(data[indextr], 'bins')"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="ArchiveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Bins')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="warehouseZoneGroups(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="MapPinIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Zone Groups')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
              <vs-dropdown-menu style="width: 190px" v-else>
                <!-- ACTIONS FOR ON CREATED -->
                <vs-dropdown-item
                  @click="warehouseKhazna(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="BriefcaseIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Khazna')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      warehouses: [],
      warehouse: {
        name: '',
        code: '',
        size: 0,
        capacity: 0,
        current_utilization: 0
      },
      type: '',
      warehouseModal: false,
      offset: 0,
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadWarehouses()
    }
  },
  methods: {
    loadWarehouses () {
      this.tableLoader = true
      const query = `?is_virtual=false&?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/warehouses/${query}`, 'get', null, true,
        (response) => {
          if (this.$store.state.AppActiveUser.userRole === 'finance') {
            response.data.results.forEach(element => {
              if (!element.is_virtual) {
                this.warehouses.push(element)
              }
            })
            this.tableLoader = false
          } else {
            this.warehouses = response.data.results
            this.tableLoader = false
          }
          this.totalRows = response.data.count
        }
      )
    },
    warehouseKhazna (data) {
      this.$router.push({
        name: 'finance-operations-warehouses-khazna',
        params: {
          warehouseID: data.code
        }
      }).catch(() => {})
    },
    warehouseShipments (data) {
      this.$router.push(
        {
          name: 'admin-warehouse-orders',
          params: {
            warehouseID: data.id
          }
        }
      ).catch(() => {})
    },
    warehouseZoneGroups (data) {
      this.$router.push(
        {
          name: 'admin-warehouse-zone-groups',
          params: {
            warehouseID: data.code
          }
        }
      ).catch(() => {})
    },
    warehouseData (data, type) {
      this.$router.push(
        {
          name: 'admin-warehouse-data',
          params: {
            warehouseID: data.code,
            type
          }
        }
      ).catch(() => {})
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.loadWarehouses()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
